@import url(https://use.typekit.net/iam7zva.css);

html,
body {
  font-size: 16px;
  line-height: 1.5;

  font-family: "servus-slab", serif;
  font-weight: 400;
  font-style: normal;

  background-color: white;

  margin: 0;
  padding: 0;
  border: none;

  width: 100%;
  height: 100%;
}

section {
  margin: 0;
  padding: 0;
  border: none;

  position: relative;

  width: 100%;
  height: 100%;

  overflow: auto;
}

h1 {
  margin: 0;
  padding: 0;
  border: none;

  font-size: 3rem;
  line-height: 4.5rem;

  position: absolute;

  top: 20%;
  left: 10%;

  color: #999;

  white-space: nowrap;

  &::before {
    content: "\0203A";
  }
}

svg {
  position: absolute;

  bottom: 0;
  right: 0;

  width: 40%;
  height: auto;

  path.lo {
    fill: #666;
  }

  path.hi {
    fill: #999;
  }

  path.lo,
  path.hi {
    fill-rule: evenodd;
  }
}

@media screen and (orientation: landscape) and (max-width: 736px) {
  h1 {
    font-size: 1.125rem;
    line-height: 3.5rem;
  }
}

@media screen and (orientation: portrait) and (max-width: 414px) {
  h1 {
    font-size: 1.125rem;
    line-height: 3.5rem;
  }
}

@media screen and (orientation: landscape) {
  html,
  body,
  section {
    min-width: 568px;
    min-height: 320px;
  }
}

@media screen and (orientation: portrait) {
  html,
  body,
  section {
    min-height: 568px;
    min-width: 320px;
  }
}
